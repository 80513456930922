@import url('../../../../node_modules/highlight.js/styles/nord.css');
@import url('custom-props.css');
@import url('general.css');
@import url('../modules/mod-header/header.css');
@import url('../modules/mod-archive/archive.css');
@import url('../modules/mod-footer/footer.css');

main {
  max-width: 94rem;
  margin: 0 auto;
  padding: 1rem;
}

article {
  padding: 4rem 0;
}
